import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`22nd October 2020`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now bind a `}<a parentName="p" {...{
            "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
          }}>{`type://GrpcService`}</a>{` to a custom route path. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3072"
          }}>{`#3072`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3083"
          }}>{`#3083`}</a>{`
This is useful if you want to serve an unframed gRPC service with RESTful routes.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = ...
sb.service(GrpcService.builder()
                      // Bind 'getProfile()' method to '/v1/api/users/profile'
                      .addService("/v1/api/users/profile", userService,
                                  UserServiceGrpc.getProfile())
                      // Bind 'getFriends()' method to '/v1/api/users/friends'
                      .addService("/v1/api/users/friends", userService,
                                  UserServiceGrpc.getFriends())
                      .build());
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<a parentName="p" {...{
            "href": "type://DecodingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/encoding/DecodingService.html"
          }}>{`type://DecodingService`}</a>{` for decoding a compressed request. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1521"
          }}>{`#1521`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3087"
          }}>{`#3087`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<a parentName="p" {...{
            "href": "type://HttpResponse#ofRedirect(String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html#ofRedirect(java.lang.String)"
          }}>{`type://HttpResponse#ofRedirect(String)`}</a>{` for easily sending a redirect response. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/914"
          }}>{`#914`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3059"
          }}>{`#3059`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// The status is HttpStatus.TEMPORARY_REDIRECT.
HttpResponse.ofRedirect("/new-location");
HttpResponse.ofRedirect("/new-location/%s", someVar);
// Redirect with the specified redirection status.
HttpResponse.ofRedirect(HttpStatus.MOVED_PERMANENTLY, "/new-location");
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now choose a path when a Thrift/gRPC service has more than one path
in the `}<a parentName="p" {...{
            "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
          }}>{`type://DocService`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2167"
          }}>{`#2167`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3024"
          }}>{`#3024`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now cancel a service's request or client's response with `}<a parentName="p" {...{
            "href": "type://CancellationException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/CancellationException.html"
          }}>{`type://CancellationException`}</a>{` or
any `}<inlineCode parentName="p">{`Throwable`}</inlineCode>{` using `}<a parentName="p" {...{
            "href": "type://RequestContext#cancel():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html#cancel()"
          }}>{`type://RequestContext#cancel()`}</a>{` or
`}<a parentName="p" {...{
            "href": "type://RequestContext#cancel(Throwable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html#cancel(java.lang.Throwable)?full"
          }}>{`type://RequestContext#cancel(Throwable)?full`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3046"
          }}>{`#3046`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3080"
          }}>{`#3080`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientRequestContext cctx = ...;
// Cancel a client's response with ResponseCancellationException
cctx.cancel();
cctx.cancel(new RuntimeException("A custom error message"));

ServiceRequestContext sctx = ...;
// Cancel a service's request with RequestCancellationException
sctx.cancel();
sctx.cancel(new RuntimeException("A custom error message"));
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`Do not launch two servers if both `}<inlineCode parentName="li">{`armeria-spring-boot2-autoconfigure`}</inlineCode>{` and
`}<inlineCode parentName="li">{`armeria-spring-boot2-webflux-autoconfigure`}</inlineCode>{` are in the class path. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3056"
        }}>{`#3056`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3102"
        }}>{`#3102`}</a></li>
      <li parentName="ul">{`You no longer see a previously selected input when the input box of `}<inlineCode parentName="li">{`Go to`}</inlineCode>{` menu is clicked. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3095"
        }}>{`#3095`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`You now get proper 404 Not Found status when `}<inlineCode parentName="li">{`routeDecorator()`}</inlineCode>{` is used and
the endpoint of the request doesn't exist. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3120"
        }}>{`#3120`}</a></li>
      <li parentName="ul">{`You no longer see duplicated MDC keys anymore when `}<inlineCode parentName="li">{`armeria-logback`}</inlineCode>{` is applied. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3044"
        }}>{`#3044`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3105"
        }}>{`#3105`}</a></li>
      <li parentName="ul">{`You can now get the normal response even though the request is not fully sent. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3115"
        }}>{`#3115`}</a></li>
      <li parentName="ul">{`You can now use milliseconds as an interval unit in `}<a parentName="li" {...{
          "href": "type://EurekaEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/eureka/EurekaEndpointGroup.html"
        }}>{`type://EurekaEndpointGroup`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3108"
        }}>{`#3108`}</a></li>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`UNKNOWN`}</inlineCode>{` error code while sending stream messages with Reactive gRPC stub. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3090"
        }}>{`#3090`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3099"
        }}>{`#3099`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`TTEXT_NAMED_ENUM`}</inlineCode>{` now correctly serializes enums when used inside maps. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3091"
        }}>{`#3091`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://BraveClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/brave/BraveClient.html"
        }}>{`type://BraveClient`}</a>{` and `}<a parentName="li" {...{
          "href": "type://BraveService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/brave/BraveService.html"
        }}>{`type://BraveService`}</a>{` do not override
the default `}<inlineCode parentName="li">{`(Client|Server)(Request|Response)Parser`}</inlineCode>{` anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3023"
        }}>{`#3023`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3111"
        }}>{`#3111`}</a></li>
      <li parentName="ul">{`Armeria client now sets the default path(`}<inlineCode parentName="li">{`/`}</inlineCode>{`) properly if a path is empty. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3098"
        }}>{`#3098`}</a></li>
      <li parentName="ul">{`You no longer see an outdated JS bundle in the browser
when the `}<a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` page is rebuilt. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3094"
        }}>{`#3094`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul">{`The following methods have been deprecated in favor of their `}<inlineCode parentName="li">{`millis`}</inlineCode>{`-accepting versions: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3108"
        }}>{`#3108`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://EurekaEndpointGroupBuilder.registryFetchIntervalSeconds()"
            }}>{`type://EurekaEndpointGroupBuilder.registryFetchIntervalSeconds()`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://EurekaUpdatingServerListener.leaseDurationSeconds()"
            }}>{`type://EurekaUpdatingServerListener.leaseDurationSeconds()`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://EurekaUpdatingServerListener.renewalIntervalSeconds()"
            }}>{`type://EurekaUpdatingServerListener.renewalIntervalSeconds()`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`The following methods have been deprecated in favor of their `}<inlineCode parentName="li">{`*Cancelling`}</inlineCode>{` and `}<inlineCode parentName="li">{`*Cancelled`}</inlineCode>{` versions: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3080"
        }}>{`#3080`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://ClientRequestContext.whenResponseTimingOut()"
            }}>{`type://ClientRequestContext.whenResponseTimingOut()`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://ClientRequestContext.whenResponseTimedOut()"
            }}>{`type://ClientRequestContext.whenResponseTimedOut()`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://ServiceRequestContext.whenRequestTimingOut()"
            }}>{`type://ServiceRequestContext.whenRequestTimingOut()`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://ServiceRequestContext.whenRequestTimedOut()"
            }}>{`type://ServiceRequestContext.whenRequestTimedOut()`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Brave 5.12.6 → 5.12.7`}</li>
      <li parentName="ul">{`Caffeine 2.8.5 → 2.8.6`}</li>
      <li parentName="ul">{`Dropwizard 1.3.24 → 1.3.25`}</li>
      <li parentName="ul">{`Dropwizard Metrics 4.1.12.1 → 4.1.13`}</li>
      <li parentName="ul">{`Eureka 1.9.26 → 1.10.7`}</li>
      <li parentName="ul">{`gRPC-Java 1.32.1 → 1.33.0`}</li>
      <li parentName="ul">{`Guava 29.0 → 30.0`}</li>
      <li parentName="ul">{`java-jwt 3.10.3 → 3.11.0`}</li>
      <li parentName="ul">{`Jetty 9.4.31 → 9.4.32`}</li>
      <li parentName="ul">{`Micrometer 1.3.12 → 1.3.14`}</li>
      <li parentName="ul">{`Netty 4.1.52 → 4.1.53`}</li>
      <li parentName="ul">{`RxJava2 2.2.19 → 2.2.20`}</li>
      <li parentName="ul">{`RxJava3 3.0.6 → 3.0.7`}</li>
      <li parentName="ul">{`Spring Boot 2.1.16 → 2.1.17`}</li>
      <li parentName="ul">{`Spring 5.1.17 → 5.1.18`}</li>
      <li parentName="ul">{`Tomcat8 8.5.57 → 8.5.58`}</li>
      <li parentName="ul">{`Tomcat9 9.0.38 → 9.0.39`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'cnabro', 'codefromthecrypt', 'ghkim3221', 'heowc', 'ikhoon', 'joonhaeng', 'jrhee17', 'KarboniteKream', 'kojilin', 'kowshikn', 'minwoox', 'policeman-kh', 'trustin', 'tumile']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      